<template>
  <div class="item-wrap" v-if="item">
    <div class="check-wrap">
      <Checkbox :label="item.id"><span></span></Checkbox>
    </div>
    <div class="content-wrap">
      <div class="title" @click="handleTitleClick(item)">
        {{ item.title }}
      </div>
      <div class="label-item">
        <div class="label">发表年份：</div>
        <div class="value">{{ item.publish_year }}</div>
      </div>
      <div class="label-item">
        <div class="label">相关生物标志物：</div>
        <div class="value">{{ biomarkers }}</div>
      </div>
      <div class="label-item">
        <div class="label">概述：</div>
        <div class="value" v-if="!isShow" ref="valueDiv">
          {{ item.abstract }}
        </div>
        <Tooltip v-else :content="item.abstract" :max-width="maxWidth">
          <div class="ellipsis">{{ item.abstract }}</div>
        </Tooltip>
      </div>
      <div class="label-item">
        <div class="label">原文下载：</div>
        <div class="value">
          <div class="btn" @click="openHandle(item.source_url)">下载链接</div>
          <div class="impactor-wrap">
            <div class="impactor-1">{{ item.journal }}</div>
            <div class="impactor-2">impact factor:{{ item.impact_factor }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Checkbox, Tooltip } from 'view-design'
export default {
  props: {
    item: {
      type: Object,
      default: () => null
    }
  },
  components: {
    Checkbox,
    Tooltip
  },
  data() {
    return {
      isShow: false,
      maxWidth: 400
    }
  },
  mounted() {
    this.$nextTick(() => {
      const valueDiv = this.$refs.valueDiv
      if (valueDiv.scrollHeight > valueDiv.clientHeight) {
        this.isShow = true
        this.maxWidth = valueDiv.clientWidth
      } else {
        this.isShow = false
      }
    })
  },
  computed: {
    biomarkers () {
      if (this.item.biomarkers) {
        return Array.from(new Set(this.item.biomarkers.split(','))).join(',')
      }
      return ''
    }
  },
  methods: {
    handleTitleClick(item) {
      if (item.article_data_type === 'article') {
        this.$router.push({
          path: '/zhiku/literature',
          query: {
            biomarkers: this.$route.query.id,
            biomarkersName: this.$route.query.name,
            id: item.id
          }
        })
      } else {
        this.$router.push({
          path: '/zhiku/guide',
          query: {
            biomarkers: this.$route.query.id,
            biomarkersName: this.$route.query.name,
            id: item.id
          }
        })
      }
    },
    openHandle(url) {
      if (url) {
        window.open(url)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.ellipsis {
  // 两行省略
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  cursor: pointer;
}
.item-wrap {
  padding: 30px 40px;
  display: flex;

  .check-wrap {
    margin-top: 4px;
  }
  .content-wrap {
    flex: 1;
  }
  .title {
    font-size: 18px;
    font-weight: bold;
    color: #202327;
    line-height: 23px;
    cursor: pointer;
    user-select: none;

    &:hover {
      color: #007fff;
    }
  }

  .label-item {
    display: flex;
    // align-items: center;
    margin-top: 12px;

    .label {
      font-size: 14px;
      color: #8e9ca9;
      line-height: 22px;
      white-space: nowrap;
    }
    .value {
      font-size: 14px;
      color: #494e53;
      line-height: 22px;
      flex: 1;
      position: relative;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;

      .btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 76px;
        height: 24px;
        background: linear-gradient(135deg, #8bcaff 0%, #3ba5fd 100%);
        box-shadow: 0px 2px 6px 0px rgba(59, 165, 253, 0.5);
        border-radius: 4px;
        color: #fff;
        cursor: pointer;
      }

      .impactor-wrap {
        display: flex;
        border-radius: 4px;
        border: 1px solid #a2bdd9;
        position: absolute;
        right: 0;
        top: 0;
        box-sizing: border-box;
        font-size: 14px;
        height: 24px;

        .impactor-1 {
          padding: 0 10px;
          color: #a2bdd9;
        }

        .impactor-2 {
          padding: 0 10px;
          background-color: #a2bdd9;
          color: #fff;
        }
      }
    }
  }
}
</style>
